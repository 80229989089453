@import "../../05-components/herobanners";

.content-hero-banner-pd {
    margin-bottom: 48px;

    .hero-wrapper-content__image {
        display: block;
    }

    @include mq($tablet-max) {
        .hero-wrapper-content__image--static {
            max-height: none;
            position: static;
        }
    }

    @include mq($mobile-max) {
        margin-bottom: 25px;
    }
}

.hero-banner {
    position: relative;
    background-color: $nero;
    min-height: 723px;

    .hero-banner__content {
        max-width: $max-width-smaller;
        margin: auto;
        padding-top: 40px;
        padding-bottom: 410px;

        @include global-padding;

        @include mq($not-mobile-tablet) {
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }

    .hero-banner__left {
        display: flex;
        flex-direction: column;

        @include mq($not-mobile-tablet) {
            width: 60%;
        }
    }

    .hero-banner__heading {
        max-width: 75vw;
    }

    .hero-banner__title {
        margin: 0 0 15px;
        @include h4;

        @include mq($not-mobile-tablet) {
            font-size: 88px;
            line-height: 90px;
            -webkit-text-stroke: 1px $white;
            color: transparent;
        }

        &--main {
            @include mq($tablet-max) {
                display: block;
                color: $white;
            }
        }

        &--opposite {
            -webkit-text-stroke: 1px $white;
            @include mq($not-mobile-tablet) {
                color: $white;
                -webkit-text-stroke: 0;
                -webkit-text-stroke: 1px $white;
            }
        }
    }

    .hero-banner__bulet-list {
        padding: 15px 0;

        li {
            color: $white;
            margin-top: 0;
            margin-bottom: 10px;
            display: flex;
            align-items: baseline;
            line-height: 20px;

            strong {
                padding: 0 5px;
            }

            @include mq($not-mobile-tablet) {
                line-height: 22px;
            }

            &::before {
                content: svg(check);
                @include iconfont-styles;

                display: inline-block;
                margin-right: 10px;
                color: $green;
                font-size: 0.85em;
                line-height: 1;
                vertical-align: middle;
            }
        }

        &.orange {
            li::before {
                color: $safety-orange;
            }
        }

        &.purple {
            li::before {
                color: $purple-heart;
            }
        }

        &.white {
            li::before {
                color: $white;
            }
        }

        &.black {
            li::before {
                color: $black;
            }
        }

        @include mq($not-mobile-tablet) {
            width: 70%;
        }
    }

    .hero-banner__paragraph {
        color: $white;
        margin-bottom: 40px;
        @include mq($not-mobile-tablet) {
            width: 70%;
        }
    }

    .hero-banner__image {
        position: absolute;
        bottom: -40%;
        right: -40%;
        height: 100%;
        transform: translateX(-50%);
        z-index: 4;

        @include mq($mobile-max) {
            transform: scale(0.5);
            bottom: -35%;
            left: 0;
            right: 70%;
        }

        @include mq($not-mobile-tablet) {
            top: 0;
            right: 0;
            width: 50%;
            transform: none;
        }

        img {
            @include mq($not-mobile-tablet) {
                width: 100%;
                object-fit: contain;
                object-position: right;
            }
        }
    }

    .hero-banner__join {
        margin-bottom: 30px;
        padding: 13px 0;
        border: 0;
        text-transform: uppercase;
        @include mq($mobile-max) {
            width: 100%;
        }
        @include mq($not-mobile-tablet) {
            width: 310px;
            margin-bottom: 25px;
        }
    }

    .hero-banner__link {
        display: none;
        color: $white;
        @include mq($not-mobile-tablet) {
            display: inline;
            width: fit-content;
        }

        &:hover {
            color: $white;
        }
    }

    .hero-banner__right {
        display: flex;
        justify-content: flex-end;
        margin-right: -16px;
    }

    &--gradient {
        background: radial-gradient(50% 50% at 50% 50%, #555 0%, $nero 100%);
    }

    &--jobs {
        @include mq($desktop-xxl) {
            margin-bottom: 0;
        }

        @include mq($not-mobile) {
            margin-bottom: 80px;
        }

        .hero-banner__left {
            @include mq($not-mobile-tablet) {
                width: 55%;
            }

            @include mq($laptop-st) {
                width: 60%;
            }
        }

        .hero-banner__image {
            bottom: -20%;

            // overiding default style for $mobile-max
            @include mq($mobile-max) {
                bottom: -25%;
                transform: scale(0.55);
                margin-left: -18px;
                margin-right: -16px;
            }

            @include mq($mobile-pt) {
                bottom: -32%;
            }

            @include mq($tablet-max) {
                bottom: -25%;
                transform: translateX(-60%) scale(0.55);
                left: 50%;
                right: 0;
            }

            @include mq($not-mobile-tablet) {
                right: 14%;
            }

            @include mq($laptop-st) {
                right: 5%;
            }
        }

        .hero-banner__paragraph {
            max-width: 700px;

            @include mq($not-mobile-tablet) {
                max-width: 405px;
            }
        }

        .hero-banner__title {
            &--main {
                @include mq($tablet-max) {
                    display: block;
                    color: $nero;
                    -webkit-text-stroke: 1px $white;
                }
            }

            &--opposite {
                color: $white;
                -webkit-text-stroke: 0;
                @include mq($not-mobile-tablet) {
                    color: $white;
                    -webkit-text-stroke: 0;
                }
            }
        }

        .hero-banner__join {
            padding: 14px 0;

            // overiding default style for $mobile-max
            @include mq($mobile-max) {
                width: 310px;
            }

            @include mq($tablet-max) {
                width: 310px;
            }

            @include mq($not-mobile-tablet) {
                width: 320px;
                margin-bottom: 25px;
            }
        }
    }

    &--video {
        min-height: 524px;

        .hero-banner__content {
            position: relative;

            @include mq($not-mobile-tablet) {
                padding-top: 82px;
                padding-bottom: 42px;
            }

            @include mq($tablet-max) {
                padding: 40px 16px 24px;
            }
        }

        .hero-banner__title--main {
            @include mq($tablet-max) {
                font-size: 47px;
                line-height: 40px;
            }
        }

        .hero-banner__title--opposite {
            @include mq($tablet-max) {
                font-size: 59px;
                line-height: 70px;
                display: block;
            }
        }

        .hero-banner__paragraph {
            @include mq($tablet-max) {
                margin-bottom: 30px;
            }
        }

        .hero-banner__left {
            position: relative;
            z-index: 1;

            .hero-banner__join {
                @include mq($tablet-max) {
                    display: none;
                }
            }
        }

        .hero-banner__right {
            position: absolute;
            bottom: -45px;
            right: 9.5%;
            width: 56%;
            display: block;
            margin-right: 0;
            opacity: 1;

            .video-block {
                .video-wrapper {
                    @include mq($not-mobile-tablet) {
                        padding: 0;
                    }

                    @include mq($tablet-max) {
                        padding: 0 0 16px;
                        justify-content: flex-start;
                    }
                }
            }

            .hero-banner__join {
                display: none;

                @include mq($tablet-max) {
                    display: block;
                    margin-bottom: 0;
                }

                @include mq($mobile-pt-l) {
                    width: 100%;
                }
            }

            @include mq($until-dt-med) {
                left: auto;
                right: 60px;
                bottom: -30px;
            }

            @include mq($until-dt-min) {
                right: 20px;
            }

            @include mq($tablet-max) {
                position: static;
                width: 100%;
            }
        }
    }

    &--video-home {
        .hero-banner__paragraph {
            max-width: 700px;

            @include mq($not-mobile-tablet) {
                max-width: 405px;
            }
        }
    }

    .badge {
        position: relative;
        max-width: 250px;
        padding: 18px;
        background-color: $white;
        color: $nero;
        z-index: 4;
        @include mq($not-mobile-tablet) {
            position: absolute;
            top: 95px;
            right: 0;
            padding: 20px 58px 24px 30px;
        }

        .badge__title {
            margin: 0;
            @include h6;
        }

        .orange {
            color: $safety-orange;
        }
    }
}
