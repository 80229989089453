// hero banners for contentopages and for example the blog landingpage

.hero-wrapper-content {
    position: relative;
    max-width: none;

    .image-wrapper {
        .img {
            width: 100%;
            // mobile
            @include ratio-prop-number(padding-top, 600px 350px);

            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            @include mq($not-mobile) {
                // desktop:
                @include ratio-prop-number(padding-top, 1920px 468px);
            }

            &.img-mobile {
                display: none;
                @include mq($mobile-max) {
                    display: block;
                }
            }

            &.img-desktop {
                display: none;
                @include mq($not-mobile) {
                    display: block;
                }
            }
        }
    }

    .title-column {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .title-wrapper {
        max-width: $max-width-centercol;
        margin: auto;
        @include global-padding;

        // same as find clubs page
        .main-title {
            color: $nero;

            @include mq($not-mobile-tablet) {
                position: static;
                margin-bottom: 45px;
                @include h2;
            }

            .title-opposite {
                text-shadow: none; // Remove after reworked
                color: transparent;
                -webkit-text-stroke: 1px $nero;

                @include mq($tablet-max) {
                    line-height: 40px;
                    color: transparent;
                    -webkit-text-stroke: 1px $nero;
                }
            }
        }
    }

    &--redesign {
        display: flex;
        flex-direction: column-reverse;
        @include mq($not-mobile-tablet) {
            flex-direction: row;
        }
        @include mq($not-mobile-tablet) {
            min-height: 460px;
        }

        .main-title {
            text-shadow: none;
            @include h4;

            position: absolute;
            top: 30px;
            word-break: break-word;
            @include mq($not-mobile-tablet) {
                position: relative;
                @include h2;
            }

            .title-opposite {
                text-shadow: none;
                -webkit-text-stroke: 1px $black;

                @include mq($tablet-max) {
                    color: transparent;
                    -webkit-text-stroke: 1px $grey-main-dark;
                }
            }

            span {
                display: block;
            }

            &.mobile-background--black,
            &.mobile-background--plain-black {
                @include mq($tablet-max) {
                    color: $white;
                }
            }
        }

        .hero-wrapper-content__image {
            position: absolute;
            width: 100vw;
            height: 100%;
            z-index: 4;

            @include mq($mobile-max) {
                max-height: 330px;
            }

            @include mq($tablet-max) {
                top: 0;
                margin-top: 115px;
                max-height: 100%;
                opacity: 0;
            }

            @include mq($tablet) {
                position: relative;
            }

            @include mq($not-mobile-tablet) {
                position: relative;
                max-height: 520px;
                top: 0;
                width: 100%;
                z-index: 0;
            }

            svg {
                max-width: 100vw;
                max-height: 460px;
                width: 100%;

                @include mq($mobile-max) {
                    height: 100%;

                    @supports (-webkit-touch-callout: none) {
                        /* CSS specific to Safari on iOS devices */
                        min-height: 400px;
                    }
                }

                @include mq($tablet-max) {
                    margin-top: 0;
                }

                @include mq($tablet) {
                    max-height: 360px;

                    @supports (-webkit-touch-callout: none) {
                        /* CSS specific to Safari on iOS devices */
                        max-height: 400px;
                    }
                }

                path {
                    opacity: 0;
                }
            }

            img {
                width: 100%;
                height: 940px;
                min-height: 100%;
                object-fit: contain;
                object-position: center;

                @include mq($safari) {
                    /* CSS specific to Safari */
                    position: relative;
                    object-fit: contain !important;
                    max-height: 400px;
                    z-index: 50;

                    &.resize-fix {
                        object-fit: contain;
                        object-position: center;
                        min-height: 400px;
                        margin-left: 145px;
                        margin-right: 30px;
                        margin-bottom: 800px;
                    }

                    @supports (-webkit-touch-callout: none) {
                        /* CSS specific to Safari on iOS devices */
                        &.resize-fix {
                            object-position: center;
                            margin-left: -120px;
                            margin-right: 180px;

                            @include mq($not-mobile) {
                                object-position: center;
                                margin-left: 180px;
                                margin-right: 0;
                            }

                            @include mq($not-mobile-tablet) {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }

            &--static {
                display: none;

                img {
                    object-fit: cover;
                    height: 100%;

                    @include mq($tablet) {
                        object-fit: contain;
                    }

                    @include mq($safari) {
                        /* CSS specific to Safari */
                        object-fit: cover !important;

                        &.resize-fix {
                            min-height: 100%;
                            margin: 0;
                        }

                        @supports (-webkit-touch-callout: none) {
                            /* CSS specific to Safari on iOS devices */
                            @include mq($tablet) {
                                object-fit: contain !important;
                            }

                            &.resize-fix {
                                margin: 0;
                            }
                        }
                    }
                }

                @include mq($not-mobile-tablet) {
                    max-height: 460px;
                }
            }
        }

        .description {
            @include body;

            @include mq($mobile-max) {
                margin-top: 65px;
            }

            @include mq($tablet-max) {
                margin-top: 0;
            }

            @include mq($not-mobile-tablet) {
                width: 80%;
            }
        }

        .left,
        .right {
            @include mq($not-mobile-tablet) {
                width: 50%;
            }
        }

        .left {
            padding: 30px 15px;

            @include mq($tablet) {
                padding-top: 0;
            }

            @include mq($not-mobile-tablet) {
                padding: 10px 30px 0 40px;
            }

            @include mq($desktop-xxl) {
                padding-left: 120px;
            }
        }

        .right {
            min-height: 375px;
        }
    }

    &--group-lessons {
        .image-wrapper {
            .img {
                height: 480px;
                overflow: hidden;

                @include mq($mobile-max) {
                    height: 223px;
                }
            }
        }

        .title-column {
            top: 115px;

            @include mq($mobile-max) {
                top: 31px;
            }
        }

        .title-wrapper {
            padding: 0 110px;

            @include mq($mobile-max) {
                padding: 0 10px;
            }

            h1 {
                @include h1;

                color: $safety-orange;
                font-family: $heading-pro-treble-heavy;

                @include mq($mobile-max) {
                    @include h4;
                }

                span {
                    display: block;
                    color: $white;
                    -webkit-text-stroke: 1px transparent;

                    @include mq($mobile-max) {
                        color: transparent;
                        -webkit-text-stroke: 1px $white;
                    }
                }
            }
        }
    }
}
