/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.hero-wrapper-content {
  position: relative;
  max-width: none;
}
.hero-wrapper-content .image-wrapper .img {
  width: 100%;
  padding-top: 58.3333333333%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .hero-wrapper-content .image-wrapper .img {
    padding-top: 24.375%;
  }
}
.hero-wrapper-content .image-wrapper .img.img-mobile {
  display: none;
}
@media only screen and (max-width: 767px) {
  .hero-wrapper-content .image-wrapper .img.img-mobile {
    display: block;
  }
}
.hero-wrapper-content .image-wrapper .img.img-desktop {
  display: none;
}
@media only screen and (min-width: 768px) {
  .hero-wrapper-content .image-wrapper .img.img-desktop {
    display: block;
  }
}
.hero-wrapper-content .title-column {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
.hero-wrapper-content .title-wrapper {
  max-width: 1088px;
  margin: auto;
  padding-left: 60px;
  padding-right: 60px;
}
@media only screen and (max-width: 1279px) {
  .hero-wrapper-content .title-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .hero-wrapper-content .title-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.hero-wrapper-content .title-wrapper .main-title {
  color: #2d2d2d;
}
@media only screen and (min-width: 1025px) {
  .hero-wrapper-content .title-wrapper .main-title {
    position: static;
    margin-bottom: 45px;
    font-size: 56px;
    line-height: 58px;
  }
}
.hero-wrapper-content .title-wrapper .main-title .title-opposite {
  text-shadow: none;
  color: transparent;
  -webkit-text-stroke: 1px #2d2d2d;
}
@media only screen and (max-width: 1024px) {
  .hero-wrapper-content .title-wrapper .main-title .title-opposite {
    line-height: 40px;
    color: transparent;
    -webkit-text-stroke: 1px #2d2d2d;
  }
}
.hero-wrapper-content--redesign {
  display: flex;
  flex-direction: column-reverse;
}
@media only screen and (min-width: 1025px) {
  .hero-wrapper-content--redesign {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-wrapper-content--redesign {
    min-height: 460px;
  }
}
.hero-wrapper-content--redesign .main-title {
  text-shadow: none;
  font-size: 32px;
  line-height: 36px;
  position: absolute;
  top: 30px;
  word-break: break-word;
}
@media only screen and (min-width: 1025px) {
  .hero-wrapper-content--redesign .main-title {
    position: relative;
    font-size: 56px;
    line-height: 58px;
  }
}
.hero-wrapper-content--redesign .main-title .title-opposite {
  text-shadow: none;
  -webkit-text-stroke: 1px #313136;
}
@media only screen and (max-width: 1024px) {
  .hero-wrapper-content--redesign .main-title .title-opposite {
    color: transparent;
    -webkit-text-stroke: 1px #9b9b9b;
  }
}
.hero-wrapper-content--redesign .main-title span {
  display: block;
}
@media only screen and (max-width: 1024px) {
  .hero-wrapper-content--redesign .main-title.mobile-background--black, .hero-wrapper-content--redesign .main-title.mobile-background--plain-black {
    color: #fff;
  }
}
.hero-wrapper-content--redesign .hero-wrapper-content__image {
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 4;
}
@media only screen and (max-width: 767px) {
  .hero-wrapper-content--redesign .hero-wrapper-content__image {
    max-height: 330px;
  }
}
@media only screen and (max-width: 1024px) {
  .hero-wrapper-content--redesign .hero-wrapper-content__image {
    top: 0;
    margin-top: 115px;
    max-height: 100%;
    opacity: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .hero-wrapper-content--redesign .hero-wrapper-content__image {
    position: relative;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-wrapper-content--redesign .hero-wrapper-content__image {
    position: relative;
    max-height: 520px;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}
.hero-wrapper-content--redesign .hero-wrapper-content__image svg {
  max-width: 100vw;
  max-height: 460px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .hero-wrapper-content--redesign .hero-wrapper-content__image svg {
    height: 100%;
  }
  @supports (-webkit-touch-callout: none) {
    .hero-wrapper-content--redesign .hero-wrapper-content__image svg {
      /* CSS specific to Safari on iOS devices */
      min-height: 400px;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .hero-wrapper-content--redesign .hero-wrapper-content__image svg {
    margin-top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .hero-wrapper-content--redesign .hero-wrapper-content__image svg {
    max-height: 360px;
  }
  @supports (-webkit-touch-callout: none) {
    .hero-wrapper-content--redesign .hero-wrapper-content__image svg {
      /* CSS specific to Safari on iOS devices */
      max-height: 400px;
    }
  }
}
.hero-wrapper-content--redesign .hero-wrapper-content__image svg path {
  opacity: 0;
}
.hero-wrapper-content--redesign .hero-wrapper-content__image img {
  width: 100%;
  height: 940px;
  min-height: 100%;
  object-fit: contain;
  object-position: center;
}
@media not all and (min-resolution: 0.001dpcm) {
  .hero-wrapper-content--redesign .hero-wrapper-content__image img {
    /* CSS specific to Safari */
    position: relative;
    object-fit: contain !important;
    max-height: 400px;
    z-index: 50;
  }
  .hero-wrapper-content--redesign .hero-wrapper-content__image img.resize-fix {
    object-fit: contain;
    object-position: center;
    min-height: 400px;
    margin-left: 145px;
    margin-right: 30px;
    margin-bottom: 800px;
  }
  @supports (-webkit-touch-callout: none) {
    .hero-wrapper-content--redesign .hero-wrapper-content__image img {
      /* CSS specific to Safari on iOS devices */
    }
    .hero-wrapper-content--redesign .hero-wrapper-content__image img.resize-fix {
      object-position: center;
      margin-left: -120px;
      margin-right: 180px;
    }
    @media only screen and (min-width: 768px) {
      .hero-wrapper-content--redesign .hero-wrapper-content__image img.resize-fix {
        object-position: center;
        margin-left: 180px;
        margin-right: 0;
      }
    }
    @media only screen and (min-width: 1025px) {
      .hero-wrapper-content--redesign .hero-wrapper-content__image img.resize-fix {
        margin-left: 0;
      }
    }
  }
}
.hero-wrapper-content--redesign .hero-wrapper-content__image--static {
  display: none;
}
.hero-wrapper-content--redesign .hero-wrapper-content__image--static img {
  object-fit: cover;
  height: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .hero-wrapper-content--redesign .hero-wrapper-content__image--static img {
    object-fit: contain;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  .hero-wrapper-content--redesign .hero-wrapper-content__image--static img {
    /* CSS specific to Safari */
    object-fit: cover !important;
  }
  .hero-wrapper-content--redesign .hero-wrapper-content__image--static img.resize-fix {
    min-height: 100%;
    margin: 0;
  }
  @supports (-webkit-touch-callout: none) {
    .hero-wrapper-content--redesign .hero-wrapper-content__image--static img {
      /* CSS specific to Safari on iOS devices */
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      .hero-wrapper-content--redesign .hero-wrapper-content__image--static img {
        object-fit: contain !important;
      }
    }
    .hero-wrapper-content--redesign .hero-wrapper-content__image--static img.resize-fix {
      margin: 0;
    }
  }
}
@media only screen and (min-width: 1025px) {
  .hero-wrapper-content--redesign .hero-wrapper-content__image--static {
    max-height: 460px;
  }
}
.hero-wrapper-content--redesign .description {
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 16px;
  line-height: 28px;
}
@media only screen and (max-width: 767px) {
  .hero-wrapper-content--redesign .description {
    margin-top: 65px;
  }
}
@media only screen and (max-width: 1024px) {
  .hero-wrapper-content--redesign .description {
    margin-top: 0;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-wrapper-content--redesign .description {
    width: 80%;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-wrapper-content--redesign .left,
.hero-wrapper-content--redesign .right {
    width: 50%;
  }
}
.hero-wrapper-content--redesign .left {
  padding: 30px 15px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .hero-wrapper-content--redesign .left {
    padding-top: 0;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-wrapper-content--redesign .left {
    padding: 10px 30px 0 40px;
  }
}
@media only screen and (min-width: 1400px) {
  .hero-wrapper-content--redesign .left {
    padding-left: 120px;
  }
}
.hero-wrapper-content--redesign .right {
  min-height: 375px;
}
.hero-wrapper-content--group-lessons .image-wrapper .img {
  height: 480px;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .hero-wrapper-content--group-lessons .image-wrapper .img {
    height: 223px;
  }
}
.hero-wrapper-content--group-lessons .title-column {
  top: 115px;
}
@media only screen and (max-width: 767px) {
  .hero-wrapper-content--group-lessons .title-column {
    top: 31px;
  }
}
.hero-wrapper-content--group-lessons .title-wrapper {
  padding: 0 110px;
}
@media only screen and (max-width: 767px) {
  .hero-wrapper-content--group-lessons .title-wrapper {
    padding: 0 10px;
  }
}
.hero-wrapper-content--group-lessons .title-wrapper h1 {
  font-size: 72px;
  line-height: 74px;
  color: #fe7000;
  font-family: "HeadingProTreble-Heavy", sans-serif;
}
@media only screen and (max-width: 767px) {
  .hero-wrapper-content--group-lessons .title-wrapper h1 {
    font-size: 32px;
    line-height: 36px;
  }
}
.hero-wrapper-content--group-lessons .title-wrapper h1 span {
  display: block;
  color: #fff;
  -webkit-text-stroke: 1px transparent;
}
@media only screen and (max-width: 767px) {
  .hero-wrapper-content--group-lessons .title-wrapper h1 span {
    color: transparent;
    -webkit-text-stroke: 1px #fff;
  }
}

.content-hero-banner-pd {
  margin-bottom: 48px;
}
.content-hero-banner-pd .hero-wrapper-content__image {
  display: block;
}
@media only screen and (max-width: 1024px) {
  .content-hero-banner-pd .hero-wrapper-content__image--static {
    max-height: none;
    position: static;
  }
}
@media only screen and (max-width: 767px) {
  .content-hero-banner-pd {
    margin-bottom: 25px;
  }
}

.hero-banner {
  position: relative;
  background-color: #2d2d2d;
  min-height: 723px;
}
.hero-banner .hero-banner__content {
  max-width: 1440px;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 410px;
  padding-left: 60px;
  padding-right: 60px;
}
@media only screen and (max-width: 1279px) {
  .hero-banner .hero-banner__content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .hero-banner .hero-banner__content {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-banner .hero-banner__content {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.hero-banner .hero-banner__left {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1025px) {
  .hero-banner .hero-banner__left {
    width: 60%;
  }
}
.hero-banner .hero-banner__heading {
  max-width: 75vw;
}
.hero-banner .hero-banner__title {
  margin: 0 0 15px;
  font-size: 32px;
  line-height: 36px;
}
@media only screen and (min-width: 1025px) {
  .hero-banner .hero-banner__title {
    font-size: 88px;
    line-height: 90px;
    -webkit-text-stroke: 1px #fff;
    color: transparent;
  }
}
@media only screen and (max-width: 1024px) {
  .hero-banner .hero-banner__title--main {
    display: block;
    color: #fff;
  }
}
.hero-banner .hero-banner__title--opposite {
  -webkit-text-stroke: 1px #fff;
}
@media only screen and (min-width: 1025px) {
  .hero-banner .hero-banner__title--opposite {
    color: #fff;
    -webkit-text-stroke: 0;
    -webkit-text-stroke: 1px #fff;
  }
}
.hero-banner .hero-banner__bulet-list {
  padding: 15px 0;
}
.hero-banner .hero-banner__bulet-list li {
  color: #fff;
  margin-top: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
  line-height: 20px;
}
.hero-banner .hero-banner__bulet-list li strong {
  padding: 0 5px;
}
@media only screen and (min-width: 1025px) {
  .hero-banner .hero-banner__bulet-list li {
    line-height: 22px;
  }
}
.hero-banner .hero-banner__bulet-list li::before {
  content: "\f107";
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  display: inline-block;
  margin-right: 10px;
  color: #7dcd19;
  font-size: 0.85em;
  line-height: 1;
  vertical-align: middle;
}
.hero-banner .hero-banner__bulet-list.orange li::before {
  color: #fe7000;
}
.hero-banner .hero-banner__bulet-list.purple li::before {
  color: #6131ad;
}
.hero-banner .hero-banner__bulet-list.white li::before {
  color: #fff;
}
.hero-banner .hero-banner__bulet-list.black li::before {
  color: #313136;
}
@media only screen and (min-width: 1025px) {
  .hero-banner .hero-banner__bulet-list {
    width: 70%;
  }
}
.hero-banner .hero-banner__paragraph {
  color: #fff;
  margin-bottom: 40px;
}
@media only screen and (min-width: 1025px) {
  .hero-banner .hero-banner__paragraph {
    width: 70%;
  }
}
.hero-banner .hero-banner__image {
  position: absolute;
  bottom: -40%;
  right: -40%;
  height: 100%;
  transform: translateX(-50%);
  z-index: 4;
}
@media only screen and (max-width: 767px) {
  .hero-banner .hero-banner__image {
    transform: scale(0.5);
    bottom: -35%;
    left: 0;
    right: 70%;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-banner .hero-banner__image {
    top: 0;
    right: 0;
    width: 50%;
    transform: none;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-banner .hero-banner__image img {
    width: 100%;
    object-fit: contain;
    object-position: right;
  }
}
.hero-banner .hero-banner__join {
  margin-bottom: 30px;
  padding: 13px 0;
  border: 0;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .hero-banner .hero-banner__join {
    width: 100%;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-banner .hero-banner__join {
    width: 310px;
    margin-bottom: 25px;
  }
}
.hero-banner .hero-banner__link {
  display: none;
  color: #fff;
}
@media only screen and (min-width: 1025px) {
  .hero-banner .hero-banner__link {
    display: inline;
    width: fit-content;
  }
}
.hero-banner .hero-banner__link:hover {
  color: #fff;
}
.hero-banner .hero-banner__right {
  display: flex;
  justify-content: flex-end;
  margin-right: -16px;
}
.hero-banner--gradient {
  background: radial-gradient(50% 50% at 50% 50%, #555 0%, #2d2d2d 100%);
}
@media only screen and (min-width: 1400px) {
  .hero-banner--jobs {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 768px) {
  .hero-banner--jobs {
    margin-bottom: 80px;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-banner--jobs .hero-banner__left {
    width: 55%;
  }
}
@media only screen and (min-width: 1367px) {
  .hero-banner--jobs .hero-banner__left {
    width: 60%;
  }
}
.hero-banner--jobs .hero-banner__image {
  bottom: -20%;
}
@media only screen and (max-width: 767px) {
  .hero-banner--jobs .hero-banner__image {
    bottom: -25%;
    transform: scale(0.55);
    margin-left: -18px;
    margin-right: -16px;
  }
}
@media only screen and (max-width: 415px) {
  .hero-banner--jobs .hero-banner__image {
    bottom: -32%;
  }
}
@media only screen and (max-width: 1024px) {
  .hero-banner--jobs .hero-banner__image {
    bottom: -25%;
    transform: translateX(-60%) scale(0.55);
    left: 50%;
    right: 0;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-banner--jobs .hero-banner__image {
    right: 14%;
  }
}
@media only screen and (min-width: 1367px) {
  .hero-banner--jobs .hero-banner__image {
    right: 5%;
  }
}
.hero-banner--jobs .hero-banner__paragraph {
  max-width: 700px;
}
@media only screen and (min-width: 1025px) {
  .hero-banner--jobs .hero-banner__paragraph {
    max-width: 405px;
  }
}
@media only screen and (max-width: 1024px) {
  .hero-banner--jobs .hero-banner__title--main {
    display: block;
    color: #2d2d2d;
    -webkit-text-stroke: 1px #fff;
  }
}
.hero-banner--jobs .hero-banner__title--opposite {
  color: #fff;
  -webkit-text-stroke: 0;
}
@media only screen and (min-width: 1025px) {
  .hero-banner--jobs .hero-banner__title--opposite {
    color: #fff;
    -webkit-text-stroke: 0;
  }
}
.hero-banner--jobs .hero-banner__join {
  padding: 14px 0;
}
@media only screen and (max-width: 767px) {
  .hero-banner--jobs .hero-banner__join {
    width: 310px;
  }
}
@media only screen and (max-width: 1024px) {
  .hero-banner--jobs .hero-banner__join {
    width: 310px;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-banner--jobs .hero-banner__join {
    width: 320px;
    margin-bottom: 25px;
  }
}
.hero-banner--video {
  min-height: 524px;
}
.hero-banner--video .hero-banner__content {
  position: relative;
}
@media only screen and (min-width: 1025px) {
  .hero-banner--video .hero-banner__content {
    padding-top: 82px;
    padding-bottom: 42px;
  }
}
@media only screen and (max-width: 1024px) {
  .hero-banner--video .hero-banner__content {
    padding: 40px 16px 24px;
  }
}
@media only screen and (max-width: 1024px) {
  .hero-banner--video .hero-banner__title--main {
    font-size: 47px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 1024px) {
  .hero-banner--video .hero-banner__title--opposite {
    font-size: 59px;
    line-height: 70px;
    display: block;
  }
}
@media only screen and (max-width: 1024px) {
  .hero-banner--video .hero-banner__paragraph {
    margin-bottom: 30px;
  }
}
.hero-banner--video .hero-banner__left {
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 1024px) {
  .hero-banner--video .hero-banner__left .hero-banner__join {
    display: none;
  }
}
.hero-banner--video .hero-banner__right {
  position: absolute;
  bottom: -45px;
  right: 9.5%;
  width: 56%;
  display: block;
  margin-right: 0;
  opacity: 1;
}
@media only screen and (min-width: 1025px) {
  .hero-banner--video .hero-banner__right .video-block .video-wrapper {
    padding: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .hero-banner--video .hero-banner__right .video-block .video-wrapper {
    padding: 0 0 16px;
    justify-content: flex-start;
  }
}
.hero-banner--video .hero-banner__right .hero-banner__join {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .hero-banner--video .hero-banner__right .hero-banner__join {
    display: block;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 550px) {
  .hero-banner--video .hero-banner__right .hero-banner__join {
    width: 100%;
  }
}
@media only screen and (max-width: 1366px) {
  .hero-banner--video .hero-banner__right {
    left: auto;
    right: 60px;
    bottom: -30px;
  }
}
@media only screen and (max-width: 1279px) {
  .hero-banner--video .hero-banner__right {
    right: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .hero-banner--video .hero-banner__right {
    position: static;
    width: 100%;
  }
}
.hero-banner--video-home .hero-banner__paragraph {
  max-width: 700px;
}
@media only screen and (min-width: 1025px) {
  .hero-banner--video-home .hero-banner__paragraph {
    max-width: 405px;
  }
}
.hero-banner .badge {
  position: relative;
  max-width: 250px;
  padding: 18px;
  background-color: #fff;
  color: #2d2d2d;
  z-index: 4;
}
@media only screen and (min-width: 1025px) {
  .hero-banner .badge {
    position: absolute;
    top: 95px;
    right: 0;
    padding: 20px 58px 24px 30px;
  }
}
.hero-banner .badge .badge__title {
  margin: 0;
  font-size: 20px;
  line-height: 24px;
}
.hero-banner .badge .orange {
  color: #fe7000;
}

.video-block {
  overflow: hidden;
  width: 100%;
}
.video-block .video-wrapper {
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 1440px;
  padding: 70px 16px;
  width: 100%;
}
@media only screen and (min-width: 1025px) {
  .video-block .video-wrapper {
    padding: 100px 16px;
  }
  .video-block .video-wrapper.video-left {
    justify-content: flex-start;
  }
  .video-block .video-wrapper.video-right {
    justify-content: flex-end;
  }
}
.video-block .video-inner {
  width: 100%;
}
.video-block .vimeo-video,
.video-block .youtube-video {
  position: relative;
  padding: 56.25% 0 0;
}
.video-block .youtube-video {
  overflow: hidden;
  position: relative;
}
.video-block .youtube-video iframe {
  height: 100%;
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.video-block .custom-video video {
  max-width: 100%;
}
@media only screen and (max-width: 1024px) {
  .video-block .custom-video video {
    height: auto;
  }
}