.video-block {
    overflow: hidden;
    width: 100%;

    .video-wrapper {
        display: flex;
        justify-content: center;
        margin: auto;
        max-width: $max-width-smaller;
        padding: 70px 16px;
        width: 100%;

        @include mq($not-mobile-tablet) {
            padding: 100px 16px;

            &.video-left {
                justify-content: flex-start;
            }

            &.video-right {
                justify-content: flex-end;
            }
        }
    }

    .video-inner {
        width: 100%;
    }

    .vimeo-video,
    .youtube-video {
        position: relative;
        padding: 56.25% 0 0;
    }

    .youtube-video {
        overflow: hidden;
        position: relative;

        iframe {
            height: 100%;
            left: 0;
            max-width: 100%;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .custom-video {
        video {
            max-width: 100%;

            @include mq($tablet-max) {
                height: auto;
            }
        }
    }
}
